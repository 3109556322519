@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/fonts';

.header {
	display: flex;
	min-height: $header-height;
	margin-bottom: floor((14px * 1.428571429));
	border: 1px solid transparent;
	align-items: center;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 5;
	height: 66px;
	box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
	padding: 0 1.875rem;
	background-color: $koalafi-white;

	.koalafi-logo {
		width: 155px;
	}

	.navbar-brand {
		margin-right: 1.875rem;
	}

	.dropdown-toggle {
		font-size: 1rem;
		font-family: $gilroy-SemiBold-font;
	}

	.dropdown-menu {
		background-color: $koalafi-white;
		color: inherit;
		border: none;
		font-size: 1rem;
		box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
		border-radius: 4px;
		min-width: 160px;
		padding: 5px 0;
		margin: 2px 0 0;
		@media (max-width: 991.98px) {
			width: 90vw;
		}

		a {
			padding-left: 1.5625rem;
			&:hover {
				background-color: $koalafi-white;
			}
		}
	}

	.item {
		display: flex;
		font-family: $gilroy-SemiBold-font;
		color: $koalafi-indigo;
		font-size: 1rem;

		a {
			letter-spacing: 0.02em;
			line-height: 1.375rem;

			span {
				margin-left: 0.25rem !important;
			}
		}
	}

	.burger-menu {
		background-color: $koalafi-white;
		margin: 0 -1.875rem;
		padding: 0 1.875rem;
	}

	button.navbar-toggler {
		padding: 0.25rem 0.75rem;
		font-size: 1.5625rem;
		line-height: 1;
		background-color: transparent;
		border: 1px solid transparent;
		border-radius: 0.25rem;

		.icon-menu {
			color: $koalafi-indigo;
		}
		&:focus,
		&:active {
			outline: none !important;
			text-decoration: none;
			box-shadow: none;
		}
	}

	@media (max-width: 575.98px) {
		padding: 0 0.5rem;
	}
}
