@import '../../assets/styles/colors.scss';
@import '../../assets/styles/fonts.scss';

.detail-container {
	font-family: $inter-Regular-font, sans-serif;
	font-size: 1.25rem;
	font-weight: 400;
	line-height: 1.5;

	h4 {
		font-family: 'Gilroy-Regular';
		font-size: 1.625rem;
		font-weight: 700;
		line-height: 1.875rem;
		margin-top: 0.625rem;
		margin-bottom: 0.625rem;
	}

	a {
		color: $koalafi-royal-blue !important;
		font-family: $inter-Regular-font, sans-serif;

		@media (max-width: 490px) {
			font-size: 1.25rem;
		}
	}

	.color-martinique {
		color: $koalafi-indigo !important;
	}

	.detail-section {
		margin-bottom: 1.5rem;

		& > * > * {
			margin-top: 0px;
			margin-bottom: 1rem;
		}

		& > * > hr {
			height: 1px;
		}
	}

	.detail {
		display: flex;
		align-items: center;

		.detail-icon {
			font-size: 1.325rem;
		}

		& > *:first-child {
			margin-right: 1rem;
		}

		@media (max-width: 490px) {
			font-size: 1rem;
		}
	}

	.detail-store-name {
		font-size: 1.5rem;

		.color-indigo {
			color: $koalafi-indigo !important;
		}
	}
}

@media (max-width: 576px) {
	.detail-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center !important;

		div {
			text-align: center;
		}

		h4 {
			text-align: center;
		}

		section > section {
			display: flex;
			flex-direction: column;
			align-items: center;

			.detail {
				display: flex;
				flex-direction: column;

				i {
					margin-right: 0rem !important;
				}

				.detail-label {
					text-align: center;
				}
			}
		}
	}
}
