// Fontello
$fontello-font: 'fontello';

// Gilroy
$gilroy-Regular-font: 'Gilroy-Regular';
$gilroy-SemiBold-font: 'Gilroy-SemiBold';

// Inter
$inter-Regular-font: 'Inter-Regular';
$inter-SemiBold-font: 'Inter-SemiBold';
$inter-Medium-font: 'Inter-Medium';

// wcf-icons-glyph
$wcf-icons-glyph-font: 'wcf-icons-glyph';
