@import 'src/assets/styles/colors';

.loan-status-container {
	background-color: $koalafi-white;
	border-radius: 5px;

	header {
		justify-content: space-between;
		align-items: flex-start;
	}

	.applicant-name {
		margin-top: 1.25rem;
		margin-bottom: 0.625rem;
		font-size: 2.75rem;
		font-weight: 700;
	}

	.loan-id {
		margin-top: 1.25rem;
		margin-bottom: 0.625rem;
		font-size: 1.875rem;
		font-weight: 400;
		line-height: 2.125rem;
	}

	.btn-cancel {
		background-color: $koalafi-ash-gray;
		color: $koalafi-royal-blue;
		:hover:enabled {
			background-color: $koalafi-sky;
		}
	}

	.btn-save {
		background-color: $koalafi-royal-blue;
		color: $koalafi-white;

		.loan-status-btn-content-container {
			display: flex;
		}
		:hover:enabled {
			color: $koalafi-sky;
		}
	}
}

.status-buttons {
	flex: auto;
	width: 50%;
}

@media (max-width: 400px) {
	.status-buttons-row {
		flex-direction: column-reverse;

		.status-buttons {
			width: 100%;

			.btn-cancel {
				margin-top: 0.5rem;
				order: 2;
			}

			.btn-save {
				margin-top: 0.5rem;
				order: 1;
			}
		}
	}
}

@media (max-width: 576px) {
	.loan-status-container {
		header {
			justify-content: center;
		}
	}

	#nameForm {
		flex-direction: column;
		justify-content: center;
		align-items: center !important;
	}

	.applicant-name {
		text-align: center;
		order: 2;
	}

	.loan-id {
		text-align: center;
		order: 1;
	}
}
