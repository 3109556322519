// Primary
$koalafi-indigo: #0d4066;
$koalafi-mist: #f3f5f5;

//Secondary
$koalafi-royal-blue: #3d6edf;
$koalafi-sky: #36c6ff;
$koalafi-teal: #1794a5;
$koalafi-spring: #49e8ab;

// Tertiary
$koalafi-iris: #8c52ff;
$koalafi-lilac: #bf94ff;
$koalafi-powder-blue: #c7e3ff;
$koalafi-oat: #f4f0e6;

// Grayscale
$koalafi-rich-black: #2d2926;
$koalafi-cool-gray: #53565a;
$koalafi-gray: #a3a3a3;
$koalafi-light-gray: #dddddd;
$koalafi-smoke-gray: #d9d9d9;
$koalafi-ash-gray: #e5e5e5;
$koalafi-white: #ffffff;

// UX Usability
$koalafi-mint: #00b286;
$koalafi-amber: #fdc913;
$koalafi-strawberry: #ee2045;
$koalafi-dark-mint: #007558;
$koalafi-dark-strawberry: #ce2543;
$koalafi-matterhorn-800: #8d8d8d;
$koalafi-matterhorn-600: #bfbfbf;
$koalafi-matterhorn-400: #4e4e4e;
$koalafi-light-tan: #ffe9c7;
$koalafi-light-blue: #e9f7fc;
