@import 'src/assets/styles/colors';

$radius: 30px;
$offset: $radius / 10;

.toggle-container {
	cursor: not-allowed;
	&:not([aria-readonly~='true']) {
		cursor: pointer;
	}

	.toggle-label {
		top: 0;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 70px;
		height: $radius;
		background: $koalafi-cool-gray;
		border-radius: 50rem;
		position: relative;
		transition: background-color 0.2s;
		color: $koalafi-white;
		font-size: 1.25rem;

		> span {
			text-transform: uppercase;
			font-size: 0.6rem;
		}

		&-checked {
			background: $koalafi-mint;
			justify-content: flex-start;
			.toggle {
				left: calc(100% - #{$offset});
				transform: translateX(-100%);
			}
		}

		&-disabled {
			justify-content: center;
			background: $koalafi-gray;

			.toggle {
				display: none;
			}
		}
	}

	.toggle {
		content: '';
		position: absolute;
		top: $offset;
		left: $offset;
		width: $radius - $offset * 2;
		height: $radius - $offset * 2;
		border-radius: 50%;
		background: $koalafi-white;
		transition: 0.1s;
	}
}
