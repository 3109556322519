@import '../../../assets/styles/colors.scss';
@import '../../../assets/styles/fonts.scss';

$left-offset: 0.5rem;
$border-radius: 5px;
$input-border: #ccc !default;

input {
	width: 100%;
	border-radius: $border-radius;
	height: 3.125rem;
	padding: 0 $left-offset;
	border: 1px solid $input-border;
	color: $koalafi-rich-black;
	background-color: $koalafi-white;
	font-family: $inter-Regular-font, sans-serif;
}

input:disabled {
	border: none;
	background-color: inherit;
}

input::placeholder {
	opacity: 1;
	color: $koalafi-rich-black;
}

.field-container {
	width: 100%;
	position: relative;
	border-radius: $border-radius;

	&.focus input {
		border: 3px solid $koalafi-sky;
	}
	&.error {
		background-color: $koalafi-dark-strawberry;
		color: $koalafi-white;
	}
}

label {
	color: $koalafi-gray;
	position: absolute;
	top: 1rem;
	padding: 0 1.3rem;
	left: 0;
	text-transform: uppercase;
	transition: all 150ms ease-in;
	pointer-events: none;
	white-space: nowrap;
	font-family: $inter-Regular-font, sans-serif;
}

input:focus ~ label:last-child,
label.isExpanded {
	top: 0;
	font-size: 12px;
	font-weight: 700;
	border: initial;
}

label.error {
	color: $koalafi-white;
	display: inline-block;
	font-size: 0.75rem;
	line-height: normal;
	top: 0;
	padding-left: 0.625rem;
	padding-top: 0.3125rem;
	margin-bottom: 0;
	overflow: hidden;
	text-align: left;
	text-overflow: ellipsis;
	position: relative;
}
