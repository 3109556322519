@import '../../assets/styles/colors.scss';

.loan-details-tabs {
	overflow: hidden;
	border-radius: 5px 5px 0 0;
	border-bottom: none;

	.loan-details-tab {
		background: inherit;
		border: none;
		cursor: pointer;
		float: left;
		outline: none;
		padding: 1rem;
		transition: 0.4s;
		color: inherit;

		&.active {
			background: $koalafi-white;
		}

		&:hover {
			color: $koalafi-indigo;
		}

		&:first-child {
			border-radius: 5px 5px 0 0;
		}
	}

	.loan-details-strong-label {
		font-family: 'Gilroy-SemiBold';
		font-size: 1.25rem;
		line-height: 2rem;
	}
}

tab-content {
	> .tab-pane {
		display: none;
	}
	> .active {
		display: block;
	}
}

.loan-details-tab-content {
	padding: 1rem;
	background: #f2f2f2;
	animation: b 1s;
}

.loan-details {
	.button {
		background-color: $koalafi-royal-blue;
		color: $koalafi-white;

		&:hover:enabled {
			background-color: $koalafi-white;
			color: $koalafi-royal-blue;
			border-radius: 5px;
		}
	}
}

.button-wrapper {
	display: inline-block;
	margin-top: 0.5rem;
	margin-right: 1rem;
	margin-bottom: 0.5rem;
}

.ecommerce-banner {
	padding: 1rem;
	line-height: 1.875rem;
	font-family: 'Inter-SemiBold';
}

@media (max-width: 575px) {
	.button-wrapper {
		display: flex !important;
		margin-top: 0.5rem;
		margin-right: 0.5rem;
		margin-bottom: 0.5rem;
		margin-left: 0.5rem;
		max-width: 325px;
	}

	.buttons {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center !important;
	}

	.loan-details-tabs {
		flex-direction: column;
		justify-content: center;
		align-items: center !important;
	}
}
