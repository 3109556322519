@import 'src/assets/styles/variables';
@import 'src/assets/styles/colors';
@import '../../assets/styles/fonts.scss';

.table-container {
	width: 100%;
	overflow-x: auto;
	table-layout: fixed;
	display: table;
}

thead {
	border-bottom: 2px solid $koalafi-ash-gray;
}

td,
th {
	padding: 0.75rem;
}

tr > th {
	font-family: 'GothamBold', 'Montserrat', Arial, Helvetica, sans-serif;
	text-transform: uppercase;
	font-size: 0.875rem;
	font-weight: 700;
	padding-bottom: 1rem;
}

tbody > tr {
	background-color: $koalafi-white;

	&:nth-child(odd) {
		background-color: $koalafi-powder-blue;
	}

	td {
		font-family: $inter-Regular-font, sans-serif;
		@media (max-width: 1010px) {
			font-size: 0.75rem;
		}
	}
}

.table-list-item {
	border-radius: $std-border-radius;
	background-color: $koalafi-powder-blue;
	margin-top: 0.5rem;
	padding: 0.75rem;

	.list-header {
		h5 {
			font-family: $gilroy-Regular-font;
			font-size: 1.625rem;
			line-height: 1.875rem;
			margin-top: 0.625rem;
			margin-bottom: 0.625rem;
		}

		p {
			font-family: $inter-Regular-font, sans-serif;
			font-weight: 400;
			line-height: 1.25;
			margin-bottom: 0.625rem;
		}
	}
}

dl {
	display: flex;
	flex-wrap: wrap;
	font-family: $inter-Regular-font, sans-serif;
	font-weight: bold;
	margin-bottom: 1.25rem;

	@media (max-width: 576px) {
		margin-top: 0.5rem;
	}

	@media (max-width: 490px) {
		font-size: 0.7rem;
	}
}

dl > * {
	flex: 0 0 50%;
}

.lists-container {
	@media (min-width: 769px) {
		display: none;
	}

	> * + * {
		margin-top: 1rem;
	}
}

.list-header {
	display: flex;
	flex-direction: column;
	align-items: center;

	h5 {
		text-transform: initial;
	}

	@media (max-width: 415px) {
		font-size: 0.875rem;
	}
}

@media (max-width: 768px) {
	.table-container {
		display: none;
	}

	.table-list-item > dl {
		align-items: center;

		dd {
			text-align: right;
		}
	}
}
