@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

.modal {
	display: block;
	height: unset;
	width: unset;
	position: absolute;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	border: 1px solid $koalafi-indigo;
	padding: 5rem;
	border-radius: $std-border-radius;
	background-color: $koalafi-white;
	
	.modal-content {
		border: none;
	}
}

.modal > div,
.modal-content > * {
	display: flex;
	flex-direction: column;
	align-items: center;
	h4 {
		font-size: 1.625rem;
		line-height: 1.875rem;
		font-weight: 700;
	}
}

.modal > div > *:not(:last-child) {
	margin-bottom: 1rem;
}
