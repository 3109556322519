@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';

.footer {
	position: relative;
	display: flex;
	justify-content: space-between;
	min-height: 66px;
	width: 100%;
	margin-top: auto;
	box-shadow: 0px -2px 4px rgb(0 0 0 / 15%);
	padding-right: 1rem;
	padding-left: 1rem;
	background-color: $koalafi-white;
	color: $koalafi-indigo;
	font-size: 1rem;
	line-height: 1.375rem;

	.contact-info {
		display: flex;
		align-items: center;

		> a,
		> p {
			margin: 0.5rem;
		}

		> a {
			font-size: 1.25rem;
			line-height: 2rem;
			font-family: $gilroy-SemiBold-font;
		}

		.contact-info-icon-phone {
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				height: 19.5px;
				width: 19.5px;
			}
		}
	}

	.company-info {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		font-size: 0.75rem;
		line-height: 0.9375rem;
		font-weight: 500;
		letter-spacing: 0.05rem;
		font-family: $inter-Medium-font;
	}

	@media (max-width: 575.98px) {
		flex-direction: column;
		.contact-info {
			flex-direction: column;
			justify-content: start;
			margin-top: 0.5rem;

			> a,
			> p {
				margin: 0 0 0.5rem 0;
			}
		}

		.company-info,
		.contact-info,
		.icon-phone {
			align-items: flex-start;
		}
	}
}
