@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';

.customer-search-results-table {
	width: 100%;

	> table {
		width: 100%;
		border-collapse: collapse;
		color: $koalafi-indigo;
		font-size: 1.25rem;
		line-height: 1.2;
		font-family: $gilroy-Regular-font;

		> thead {
			> tr {
				border-radius: 0.3125rem;

				&:first-child {
					border-bottom: solid 0.125rem $koalafi-light-gray;
				}
				> th {
					padding: 1rem 0.5rem;
					font-size: 1rem;
					text-transform: uppercase;
					font-weight: normal;
					border-bottom-width: 0;
					vertical-align: top;
					text-align: center;

					&:first-child {
						text-align: left;
					}
				}
			}
		}
		> tbody {
			> tr {
				border-radius: 0.3125rem;
				padding: 0.5rem;
				border-bottom: solid 0.125rem $koalafi-light-gray;
				background-color: $koalafi-white;

				&:hover {
					cursor: pointer;
					background-color: #f2f2f2;
				}
				> td {
					padding: 1rem 0.5rem;
					text-align: center;
					&:first-child {
						border-radius: 0.3125rem 0 0 0.3125rem;
						text-align: left;
					}
					small {
						font-size: 1rem;
						margin-top: 0.25rem;
						color: $koalafi-sky;
						display: block;
					}
				}
			}
		}
	}

	.no-results {
		color: $koalafi-matterhorn-400;
		background: $koalafi-smoke-gray;
		padding: 1rem;
		line-height: 1.8;
		border-radius: 5px;
		margin-top: 1rem;

		p {
			font-family: $gilroy-Regular-font;
			font-weight: 400;
			line-height: 1.25;
			margin: 0 0 10px;
		}
	}
	.app-status {
		display: block;
		font-size: 0.875rem;
		letter-spacing: 0.5px;
		line-height: 1rem;
		padding: 0.5rem 0.875rem;
		border-radius: 0.5rem;
		font-weight: 700;
		text-align: center;

		&.app-status-btn {
			&-approved,
			&-funded {
				background-color: $koalafi-sky;
				color: $koalafi-rich-black;
			}

			&-accepted,
			&-delivered,
			&-pre-approved {
				background-color: $koalafi-powder-blue;
				color: $koalafi-rich-black;
			}

			&-declined {
				background-color: $koalafi-light-tan;
				color: $koalafi-rich-black;
			}

			&-docsready,
			&-fulfilling {
				background-color: $koalafi-lilac;
				color: $koalafi-rich-black;
			}

			&-expired {
				background-color: $koalafi-light-gray;
				color: $koalafi-rich-black;
			}

			&-draft,
			&-drafted {
				background-color: $koalafi-ash-gray;
				color: $koalafi-rich-black;
			}

			&-errorsfound,
			&-datarequested,
			&-pending {
				background-color: $koalafi-dark-strawberry;
				color: $koalafi-white;
			}
		}
	}
	@media (max-width: 575.98px) {
		> table {
			width: 100%;
			border-collapse: collapse;
			color: $koalafi-indigo;
			font-size: 1.25rem;
			line-height: 1.2;
			font-family: $gilroy-Regular-font;

			> thead {
				display: none;
			}
			> tbody {
				> tr {
					border-radius: 0.3125rem;
					background-color: $koalafi-light-blue;
					display: flex;
					flex-direction: column;
					padding: 0 0.75rem;
					margin-bottom: 1rem;

					> td {
						padding: 1rem 0.5rem;
						text-align: center;
						border-bottom: solid 0.125rem $koalafi-light-gray;
						padding: 0.75rem 0;
						order: 2;
						display: inline-flex;
						width: 100%;
						text-align: right;
						justify-content: space-between;

						&:first-child {
							border-radius: 0.3125rem 0 0 0.3125rem;
							text-align: left;
						}
						&:last-child {
							border: none;
						}
						&::before {
							content: attr(data-label);
							color: $koalafi-matterhorn-800;
							text-transform: none;
						}
						small {
							font-size: 1rem;
							margin-top: 0.25rem;
							color: $koalafi-sky;
							display: block;
						}
					}
				}
			}
		}
	}
}
