@import 'assets/styles/variables';

.main {
	min-height: 100%;
	padding-top: $header-height + floor((14px * 1.428571429));
	padding-left: 2.5rem;
	padding-right: 2.5rem;
}

body.modal-open {
	overflow: hidden;
}
