@import '../../assets/styles/colors.scss';
@import '../../assets/styles/fonts.scss';

.overview-container {
	background-color: $koalafi-white;
	border-radius: 5px;
	padding: 1rem;
	> * + * {
		margin-top: 1.5rem;
	}
}

.submit-container {
	display: flex;
	align-items: center;
	&-button {
		color: $koalafi-indigo;

		&:hover:enabled {
			background-color: $koalafi-white;
			color: $koalafi-indigo;
			border-radius: 5px;
		}
	}
}

.overview-content-item {
	background: $koalafi-white;
	border-radius: 5px;
	padding: 1rem;
	height: 100%;
	overflow-x: hidden; //fix ie small screen. labels + scroll
	p {
		font-family: $inter-Regular-font, sans-serif;
		line-height: 1.25;
	}
}
