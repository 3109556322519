@import '../../assets/styles/colors.scss';
@import '../../assets/styles/fonts.scss';

.button-container {
	border: none;
	outline: none;
	font-family: $inter-Regular-font, sans-serif;
	font-weight: 700;
	text-transform: uppercase;
	cursor: pointer;
	text-decoration: none;
	white-space: nowrap;
	border-radius: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	position: relative;
	.spinner {
		position: absolute;
		margin: auto;
		top: -0.5rem;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
	}
	.loading {
		visibility: hidden;
	}

	&-large {
		font-size: 2rem;
		padding: 1rem 2rem;
	}

	&-standard {
		padding: 0.75rem;

		@media (max-width: 576px) {
			font-size: 0.75rem;
		}
	}

	&-small {
		font-size: 0.875rem;
	}

	& > * + * {
		margin-left: 0.5rem;
	}

	svg {
		fill: $koalafi-white;
	}

	&:disabled {
		cursor: not-allowed;
	}

	&:hover:enabled {
		filter: brightness(120%);
		transform: scale(1.01);
	}
}
