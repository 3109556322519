@import 'src/assets/styles/colors';

.principle-modal-content {
	max-width: 500px;
	border: none;
}

.principle-amounts-container {
	display: flex;
	flex-direction: column;
}

.principle-button-container {
	display: flex;
	flex-direction: row;
}

.principle-modal-button {
	Button {
		color: $koalafi-indigo;
	}
}

@media (max-width: 576px) {
	.loan-principal-header {
		text-align: center;
	}
}
