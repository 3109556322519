@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';
@import 'src/assets/styles/icons';

.customer-search {
	border-radius: 5px;
	width: 100%;
	font-family: $inter-Regular-font;
	position: relative;
	text-align: left;
	margin-top: -5px;
	min-width: 100%;

	[class*='icon-'] {
		color: $koalafi-matterhorn-800;
		cursor: pointer;
		display: inline-block;
		right: 40px;
		z-index: 2;
		background-color: transparent;
		width: auto;
		top: 1rem;

		&:before {
			margin-right: 0;
		}

		& ~ .control-label {
			max-width: calc(100% - 3.125rem);
		}
	}

	.form-control {
		color: $koalafi-indigo;
		background-color: transparent;
		height: 46px;
		position: relative;
		z-index: 3;
		transition: width 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
		max-width: 155px;
		padding: 1rem 2.5rem 0.4375rem 0.5rem;
		@media (max-width: 991.98px) {
			max-width: 100%;
		}
		&:focus {
			background-color: rgba(255, 255, 255, 0.2);
			border-color: rgba(255, 255, 255, 0.3);
			box-shadow: none !important;
		}

		&:focus ~ .control-label,
		&:active ~ .control-label,
		&.filled ~ .control-label {
			transform: translateY(-0.69em) scale(0.5) perspective(100px) translateZ(0);
			max-width: 100%;
			text-transform: uppercase;
			color: $koalafi-cool-gray;
			font-weight: 600 !important;
			left: 5px;
		}

		&:focus ~ .btn-search,
		&:active ~ .btn-search,
		&.filled ~ .btn-search {
			color: inherit;
			right: 13px !important;
			z-index: 4 !important;
		}
	}

	.control-label {
		color: $koalafi-gray;
		text-align: left;
		text-overflow: ellipsis;
		left: 2px;
		padding: 0.1rem 0 0 0.625rem;
		line-height: 1.1;
		position: absolute;
		top: 1rem !important;
		pointer-events: none;
		transform-origin: 0 0;
		transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
			color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
		white-space: nowrap;
		overflow: hidden;
		justify-content: flex-start;
		border-top-left-radius: 5px;
		max-width: calc(100% - 3.125rem);
		font-size: 1rem !important;
		transform: translateX(0.8rem);
		text-transform: capitalize;
	}

	.btn-search {
		color: inherit !important;
		transition: all 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
		transition-property: right, left;
		position: absolute;
		right: calc(100% - 1.25rem);
		padding: 0;
		line-height: 1;
		background: transparent;
		border: none;
		z-index: 0;
	}
}
