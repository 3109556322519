@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';

.badge {
	border-radius: 0.5rem;
	font-size: 0.875rem;
	font-weight: bold;
	background-color: $koalafi-indigo;
	color: $koalafi-rich-black;
	text-transform: uppercase;

	&-approved,
	&-funded {
		background-color: $koalafi-sky;
		.koalafi-badge__control {
			background-color: $koalafi-sky;
		}
		.koalafi-badge__single-value {
			color: $koalafi-rich-black;
		}
	}

	&-accepted,
	&-delivered {
		background-color: $koalafi-powder-blue;
		.koalafi-badge__control {
			background-color: $koalafi-powder-blue;
		}
		.koalafi-badge__single-value {
			color: $koalafi-rich-black;
		}
	}

	&-declined {
		background-color: $koalafi-light-tan;
		.koalafi-badge__control {
			background-color: $koalafi-light-tan;
		}
		.koalafi-badge__single-value {
			color: $koalafi-rich-black;
		}
	}

	&-docs_ready {
		background-color: $koalafi-lilac;
		.koalafi-badge__control {
			background-color: $koalafi-lilac;
		}
		.koalafi-badge__single-value {
			color: $koalafi-rich-black;
		}
	}

	&-expired {
		background-color: $koalafi-light-gray;
		.koalafi-badge__control {
			background-color: $koalafi-light-gray;
		}
		.koalafi-badge__single-value {
			color: $koalafi-rich-black;
		}
	}

	&-draft {
		background-color: $koalafi-ash-gray;
		.koalafi-badge__control {
			background-color: $koalafi-ash-gray;
		}
		.koalafi-badge__single-value {
			color: $koalafi-rich-black;
		}
	}

	&-errors_found,
	&-data_requested {
		background-color: $koalafi-dark-strawberry;
		.koalafi-badge__control {
			background-color: $koalafi-dark-strawberry;
		}
		.koalafi-badge__single-value {
			color: $koalafi-white;
		}
	}
}

.koalafi-badge {
	&__indicators {
		position: absolute !important;
		top: 0 !important;
		right: 1rem !important;
		@media (max-width: 400px) {
			position: static !important;
		}
	}
	&__indicator {
		color: white !important;
		padding: 0 !important;

		&:hover {
			color: white !important;
		}
	}
	&__control {
		min-width: 250px;
		background-color: $koalafi-sky;
		border-style: none !important;
		min-height: 1.5rem !important;

		@media (max-width: 400px) {
			min-width: 150px;
		}

		&--menu-is-open {
			box-shadow: none !important;
		}

		&--is-focused {
			border: none;
			box-shadow: none !important;
		}
	}

	&__value-container {
		align-items: center !important;
		justify-content: center !important;
		padding: 0 !important;

		#react-select-2-input,
		#react-select-3-input {
			height: 1em !important;
		}
	}
	&__single-value {
		font-size: 0.875rem;

		@media (max-width: 400px) {
			font-size: 0.75rem;
		}
	}
	&__menu {
		margin-top: -0.5em !important;
	}
	&__menu-list {
		padding: 0 !important;
	}
	&__option {
		color: $koalafi-indigo !important;
		font-family: 'Montserrat', sans-serif !important;
		font-size: 1.25rem !important;
		line-height: 1.5;
		text-align: left !important;

		&--is-selected {
			background-color: $koalafi-gray !important;
		}
		&--is-focused {
			color: $koalafi-white !important;
			background-color: $koalafi-sky !important;
		}

		@media (max-width: 400px) {
			font-size: 0.75rem !important;
		}
	}
}
