@import 'src/assets/styles/colors';

.pagination {
	margin-top: 0.5rem;

	.page-item {
		outline: none;
		.page-link {
			position: relative;
			display: block;
			padding: 0.5rem 0.75rem;
			margin-left: -1px;
			line-height: 1.25;
			color: $koalafi-royal-blue !important;
			background-color: $koalafi-white;
			border: 1px solid $koalafi-light-gray;

			&:hover {
				color: $koalafi-indigo;
				text-decoration: none;
				background-color: $koalafi-ash-gray;
				border-color: $koalafi-light-gray;
			}

			&:focus {
				outline: none;
				box-shadow: 0 0 0 0.2rem rgb(13 64 102 / 25%);
			}
		}
		&.active .page-link {
			color: $koalafi-white !important;
			background-color: $koalafi-royal-blue;
		}

		&.disabled .page-link {
			cursor: not-allowed !important;
			background-color: $koalafi-matterhorn-600;
			color: $koalafi-matterhorn-800 !important;
		}
	}
}
