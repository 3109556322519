@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';
@import 'src/assets/styles/variables';
@import 'src/assets/styles/icons';
@import '~bootstrap/scss/bootstrap.scss';

html,
body {
	height: 100%;
	margin: 0;
	@media (max-width: 575.98px) {
		font-size: 20px;
	}
}

body {
	margin: 0;
	font-family: $gilroy-Regular-font;
	font-size: 1.25rem;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	color: $koalafi-indigo;
	background-color: $koalafi-mist;
}

* {
	box-sizing: border-box;
}

#root {
	height: 100%;
}

a {
	color: $koalafi-indigo !important;
	letter-spacing: 0.02em;
	line-height: 1.1rem;
	background: none;
	text-decoration: none !important;

	&:hover {
		color: $koalafi-royal-blue !important;
	}
}

p {
	line-height: 1;
	margin: 0;
	font-family: $gilroy-SemiBold-font, sans-serif;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
	margin: 0;
	padding: 0;
	border: 0;
	vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

ol,
ul {
	list-style: none;
}
