@charset "UTF-8";
@import 'fonts';

%icon {
	font-family: $fontello-font;

	font: {
		size: inherit;
		style: normal;
		weight: 400;
		variant: normal;
	}

	text: {
		decoration: inherit;
		align: center;
		transform: none;
	}

	display: inline-block;
	width: 1em;
	line-height: 1em;
}

[class*=' icon-']:before,
[class^='icon-']:before,
.fa {
	@extend %icon;
}

.icon-home:before,
.content-icon-home {
	content: '\e86b';
}

.icon-grid:before,
.content-icon-grid {
	content: '\e866';
}

.icon-file-text:before,
.content-icon-file-text {
	content: '\e85d';
}

.icon-help-circle:before,
.content-icon-help-circle {
	content: '\E86A';
}

.icon-settings:before,
.content-icon-settings {
	content: '\E8B3';
}

.icon-log-out:before,
.content-icon-log-out {
	content: '\E87A';
}

.icon-menu:before,
.content-icon-menu {
	content: '\e880';
}

.icon-briefcase:before,
.content-icon-briefcase {
	content: '\e821';
}

.icon-calendar:before,
.content-icon-calendar {
	content: '\E822';
}

.icon-phone:before,
.content-icon-phone {
	content: '\E89E';
}

.icon-mail:before,
.content-icon-mail {
	content: '\E87B';
}

.icon-save:before,
.content-icon-save {
	content: '\E8AF';
}

.icon-search:before,
.content-icon-search {
	content: '\E8B1';
}

.icon-alert-triangle:before,
.content-icon-alert-triangle {
    content: "\E804";
}
