@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

.homeownership {
  &-modal-content {
    h4 {
      font-size: 1.625rem;
      line-height: 1.875rem;
      font-weight: 700;
    }
  }

  &-button-container {
    display: flex;
    flex-direction: row;
  }

  &-text {
    text-align: center;
  }

  &-error-text {
    color: $koalafi-dark-strawberry
  }

  &-yes-modal-button {
    flex: 1;

    Button {
      text-transform: none;
      background: $koalafi-royal-blue;
      color: $koalafi-white;
    }
  }

  &-no-modal-button {
    flex: 1;

    Button {
      text-transform: none;
      background: $koalafi-light-gray;
      color: $koalafi-royal-blue;

    }
  }

  &-close-modal-button {
    position: absolute;
    top: -50px;
    right: -50px;

    > svg {
      height: 20px;
      width: 20px;
      color: $koalafi-rich-black;
    }

  }
}