@import 'src/assets/styles/colors';

.flag-container {
	background-color: $koalafi-royal-blue;
	border-radius: 0.5rem;
	color: $koalafi-white;
	font-size: 0.75rem;
	font-weight: bold;
	padding: 0.25rem 0.75rem;
	white-space: nowrap;

	@media (max-width: 576px) {
		margin-top: 0.75rem;
	}
}
