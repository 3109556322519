@import 'src/assets/styles/colors';
@import '../../assets/styles/fonts.scss';

@media (max-width: 768px) {
	.progress-container {
		display: block !important;
	}
}

.progress-container > * + * {
	margin-top: 0.33rem;
}

.progress-label-container,
.progress-annotations-container {
	display: flex;

	@media (max-width: 575px) {
		display: block;
	}
}

.progress-annotations-container > * {
	flex: 1 1 0%;
	text-align: center;
}

.annotation-header {
	font-weight: 700;
	span {
		font-family: $inter-Regular-font, 'sans-serif';
	}
}

.annotation-description {
	font-family: $inter-Regular-font, 'sans-serif';
	font-size: 0.875rem;
}

.icon-container {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	margin-bottom: 0.25rem;
	fill: $koalafi-indigo;
}

.progress-label-container > * {
	flex: 1 1 0%;
	text-align: center;
}

.progress-label-title {
	text-transform: uppercase;
	font-family: $inter-Regular-font, 'sans-serif';
	font-weight: 700;
	font-size: 0.875rem;
}

.progress-label-day {
	font-family: $inter-Regular-font, 'sans-serif';
	font-size: 0.875rem;
}

.progress-label-time {
	font-family: $inter-Regular-font, 'sans-serif';
	font-size: 0.875rem;
	color: $koalafi-sky;
}
