@import 'src/assets/styles/colors';
@import 'src/assets/styles/variables';

.Modal {
	position: absolute;
	top: 0;
	right: 0;
	bottom: auto;
	left: 0;
	transform: translate(0, 0);
	border-radius: $std-border-radius;
	background-color: $koalafi-white;
	width: 600px;
	z-index: 1050;
	border: 1px solid rgba(0, 0, 0, 0.2);
	outline: 0;
	@media (max-width: 575.98px) {
		width: auto;
	}
}

.modal-dialog {
	box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
	max-width: 34.375rem;
}

.Overlay {
	position: fixed;
	z-index: 1040;
	inset: 0px;
	background-color: transparent;
	overflow-y: auto;
	overflow-x: hidden;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.Modal > div,
.modal-content > * {
	display: flex;
	align-items: center;
	width: 100%;
	border: none;
	background-color: transparent;
}

.Modal > div > *:not(:last-child) {
	margin-bottom: 1rem;
}

.close-modal-button {
	background-color: transparent;
	border: none;
	float: right;
	> svg {
		height: 20px;
		width: 20px;
		color: $koalafi-indigo;
	}
}

.modal-title {
	font-size: 1.5rem;
	color: #333;
}

.modal-header {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid $koalafi-ash-gray;
	flex-direction: row;

	> h4 {
		font-size: 1.5rem;
		font-weight: 400;
	}
}

.modal-body {
	display: flex;
	flex-direction: column;
}

.results-pagination {
	width: 100%;
	float: left;
}
